import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationConfig } from '../../public-api';

@Component({
  selector: 'app-pagination-ui',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './pagination-ui.component.html',
  styleUrls: ['./pagination-ui.component.scss'],
})
export class PaginationUiComponent implements OnInit, AfterViewInit {
  @Input() config: PaginationConfig = { itemsPerPage: 10, totalItems: 0, currentPage: 1 };
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() limitChange: EventEmitter<number> = new EventEmitter<number>();

  public limitArray: Array<number> = [10, 20, 30, 50, 100];
  public backArrow: string = '<'
  public nextArrow: string = '>'
  public firstPageArrow: string = '<<'
  public lastPageArrow: string = '>>'
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void { this.cdr.detectChanges(); }

  public changeToPreviousPage() {
    if (!this.previousPage) {
      return;
    } else {
      this.pageChange.emit(this.previousPage);
    }
  }

  public changeToNextPage() {
    if (!this.nextPage) {
      return;
    } else {
      this.pageChange.emit(this.nextPage);
    }
  }
  public changeToFirstPage() {
    if (!this.previousPage) {
      return;
    } else {
      this.pageChange.emit(1);
    }
  }

  public changeToLastPage() {
    if (!this.nextPage) {
      return;
    } else {
      this.pageChange.emit(this.totalPages);
    }
  }

  public changeLimit(limit: number) {
    if (!limit) {
      return;
    } else {
      this.limitChange.emit(limit);
    }
  }

  public get totalPages() {
    const limit = this.config.itemsPerPage;
    const totalItems = this.config.totalItems;
    return totalItems < limit ? 1 : Math.ceil(totalItems / limit);
  }

  public get previousPage() {
    const previousPage = this.currentPage - 1;
    return previousPage && this.currentPage !== 1 ? previousPage : null;
  }

  public get nextPage() {
    const nextPage = this.currentPage + 1;
    return nextPage <= this.totalPages ? nextPage : null;
  }

  public get currentPage() { return this.config.currentPage; }

  public changePage() { }
}
