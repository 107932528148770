import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { TokenStorageService } from 'src/app/core/services';

@Component({
  selector: 'app-no-permissions-found',
  templateUrl: './no-permissions-found.component.html',
  styleUrls: ['./no-permissions-found.component.scss']
})
export class NoPermissionsFoundComponent {
  constructor(public translateService: TranslateService, public _mainService: CommonService, private _router: Router, private tokenService: TokenStorageService) { }

  logout(): void {
    this._mainService.clearCookie();
    this.tokenService.clearTokenSession()
    this._router.navigate(['/auth/login']);
  }

  support(): void { this._router.navigate(['/cms/support-and-contact']); }
}
