import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import {
  API_Routes,
  CountrySchema,
  HttpResponse,
  ProfileFormSchema,
  UserDetailSchema,
} from '../public-api';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class MainService {
  public profileDetail$: BehaviorSubject<UserDetailSchema | string> =
    new BehaviorSubject<UserDetailSchema | string>('');
    public loadImage: Subject<any> = new Subject();
  constructor(private http: HttpClient) {}

  imageLoaded() { this.loadImage.next(true); }

  // public httpGetCountry(): Observable<HttpResponse<CountrySchema[]>> {
  //   return this.http.get<HttpResponse<CountrySchema[]>>(
  //     API_Routes.USERS.GET_COUNTRY,
  //   );
  // }

  public httpRoleList(): Observable<
    HttpResponse<{ data: any[]; count: number }>
  > {
    return this.http
      .get<HttpResponse<{ data: any[]; count: number }>>(API_Routes.ROLES.LIST)
      .pipe(
        map((res) => {
          if (res && res.data && res.data.data && res.data.data.length) {
            res.data.data = res.data.data.map((role) => {
              role.name =
                role && role.name ? role.name.replaceAll('-', ' ') : '';
              return role;
            });
          }
          return res;
        }),
      );
  }

  public httpgetprofile(): Observable<HttpResponse<UserDetailSchema>> {
    return this.http.get<HttpResponse<UserDetailSchema>>(API_Routes.PROFILE.GET).pipe(map((res) => {
      this.profileDetail$.next(res && res.data ? res.data : '');
      return res;
    }));
  }


  public httpVerifyEmail(payload: any): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(API_Routes.PROFILE.VERIFY, payload).pipe(map((res) => {
      return res;
    }));
  }


  public httpUpdateProfile(
    payload: ProfileFormSchema,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      API_Routes.PROFILE.GET,
      payload,
    );
  }

  public httpDeleteUser(
    id: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(
      `${ API_Routes.PROFILE.GET}/${id}`,
    );
  }

  public httpGetNotificationSetting(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(API_Routes.NOTIFICATION.GET);
  }

  public httpUpdateNotifyStatus(type: string, id: string): Observable<HttpResponse<{ code: string }>> { 
    return this.http.patch<HttpResponse<{ code: string }>>( 
      `${API_Routes.NOTIFICATION.GET}${id}/${type}`, '');
  }

  scrollTo(el: Element): void {
    if (el) {
      return el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      return;
    }
  }

  scrollToError(): void {
    const firstElementWithError: any = document.querySelector(
      '.ng-invalid[formControlName]',
    );
    const firstTagtWithError: any = document.getElementsByTagName(
      'app-validation-error',
    );
    if (firstElementWithError) {
      return this.scrollTo(firstElementWithError);
    } else if (firstTagtWithError && firstTagtWithError[0]) {
      return this.scrollTo(firstTagtWithError[0]);
    }
  }
}
