import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TitleStrategy } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageTitleService, PageLoaderComponent } from './shared/public-api';
import { HttpClientModule } from '@angular/common/http';
import { I18nModule } from './i18n/i18n.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChartsModule } from 'ng2-charts';
import { CmsComponent } from './shared/components/cms/cms.component';
import { TermsComponent } from './shared/components/cms/terms/terms.component';
import { PrivacyComponent } from './shared/components/cms/privacy/privacy.component';
import { VerifyEmailComponent } from './shared/components/verify-email/verify-email.component';
import { AddMoreComponent } from './modals/add-more/add-more.component';
import { SupportComponent } from './shared/components/cms/support/support.component';
import { FaqComponent } from './shared/components/cms/faq/faq.component';
import { AboutUsComponent } from './shared/components/cms/about-us/about-us.component';
import { CountryFlagDirective } from './shared/directives/country-flag.directive';
import { NoPermissionsFoundComponent } from './shared/components/no-permissions-found/no-permissions-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReportReasonComponent } from './modals/report-reason/report-reason/report-reason.component';
import { DeleteUserDataComponent } from './shared/components/cms/delete-user-data/delete-user-data.component';


@NgModule({
  declarations: [AppComponent, PageLoaderComponent, CmsComponent, TermsComponent, PrivacyComponent, VerifyEmailComponent, AddMoreComponent, SupportComponent, 
    FaqComponent, AboutUsComponent, CountryFlagDirective, NoPermissionsFoundComponent, ReportReasonComponent, DeleteUserDataComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    I18nModule,
    NgbModule,
    PaginationModule,
    HttpClientModule,
    TranslateModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    ImageCropperModule,
    SlickCarouselModule,
    ChartsModule
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleService,
    },
    NgbActiveModal,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
