import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { EventBusService, TokenStorageService } from './core/services';
import { Subscription } from 'rxjs';
import { Events } from './core/enums';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'rsa';
  public isLoaderVisible: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private eventBusService: EventBusService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.eventBusService.on(Events.LOADING_START, () => {
        this.loaderValue = true;
      }),
    );

    this.subscription.add(
      this.eventBusService.on(Events.LOADING_END, () => {
        this.loaderValue = false;
      }),
    );

    this.subscription.add(
      this.eventBusService.on(Events.SIGNOUT, () => {
        this.signOut();
      }),
    );
  }

  private set loaderValue(event: boolean) {
    this.isLoaderVisible = event || false;
    this.cdr.detectChanges();
  }

  public signOut() {
    this.tokenStorageService.clearTokenSession();
    this.router.navigate(['/auth/login']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
