<header class="main-header dark-bg py-2">
  <div class="container-fluid px-4">
    <div class="row align-items-center">
      <div class="col-md-6 col-12">
        <div class="brand-logo position-relative">
          <a routerlink="/"><img alt=" " class="img-fluid img-loog" src="assets/images/logo.png" /></a>
          <div class="mobile-menu" (click)="toggleField()">
            <i class="fas fa-bars"></i>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-2 mt-md-0">
        <div
          class="nav-rigt w-max-content ms-auto d-flex align-items-center justify-content-md-end justify-content-between">
          <div class="panel panel-default">
            <div class="panel-body">
              <!-- Single button -->
              <div class="btn-group pull-right top-head-dropdown me-4" style="width: 60px;">
                <button type="button" class="btn btn-default no-drop position-relative" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" style="min-width: 22px !important;">
                  <img src="assets/images/bell.svg" class="width-24" alt="Notification" title="Notification">
                  <span *ngIf="notifyCount>0"
                    class="counter position-absolute rounded-pill text-white">{{notifyCount}}</span>
                </button>
                <div class="notification-drop dropdown-menu p-0">
                  <div *ngIf="notifyList?.length===0" class="no-nofication text-center my-4 px-3">
                    <img src="assets/images/no-notification.png" class="mx-auto my-3">
                    <h5 class="fs-18">No Notifications Found</h5>
                    <p class="fs-13 text-grey-custom fw-500">You have currently no notificatons. We'll notify
                      you when something new arrives'</p>
                  </div>
                  <div *ngIf="notifyCount>0" class="clear d-flex justify-content-between">
                    <h5>Notifications</h5>
                    <span class="cursor-pointer markread" (click)="readAllNotify()">Read all</span>
                  </div>
                  <ul class="dropdown-menu-right p-0 mb-0">
                    <li *ngFor="let item of notifyList">
                      <a (click)="readNotify(item, item.type)" class="top-text-block d-flex">
                        <div class="wrap">
                          <div class="top-text-heading fs-15">{{item?.notification_recieved.sub_title}}</div>
                          <div class="top-text-light">{{item?.notification_recieved?.created_at | date }}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <div *ngIf="notifyCount>0" class="clear d-flex justify-content-between">
                    <span routerLink="/panel/notification" class="cursor-pointer markread">View all</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dropdown w-max-content ps-1">
            <button type="button" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle border-0">
              <i class="far fa-user"></i>
              <span class="ms-2 fs-14">{{ profileDetail && profileDetail.profile && profileDetail.profile.name ?
                profileDetail.profile.name : '' }}</span>
            </button>
            <ul class="dropdown-menu p-0 fs-14 overflow-hidden">
              <li><a class="dropdown-item text-decoration-none py-2" routerLink="/panel/manage-profile"
                  routerLinkActive="active">Manage Profile</a>
              </li>
              <li><a class="dropdown-item text-decoration-none py-2" (click)="logout()"
                  routerLinkActive="active">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>