import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-delete-user-data',
  templateUrl: './delete-user-data.component.html',
  styleUrls: ['./delete-user-data.component.scss']
})
export class DeleteUserDataComponent {
  @Input() content: string = ''
}
