import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements AfterViewInit {
  @Input() isVisible: boolean = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void { this.cdr.detectChanges(); }
}
