import { Injectable } from '@angular/core';
import { API_Routes, HttpResponse } from '../public-api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  public httpGetNotificationList(
    limit?: number,
    page?: number,
    orderBy?: string,
  ): Observable<HttpResponse<any>> {
    const url = `limit=${limit}&page=${page}`;
    return this.http.get<HttpResponse<any>>(`${API_Routes.HEADER.BELL}?${url}`);
  }

  public httpUpdateNotifyStatus(
    notify_id: string,
  ): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_Routes.HEADER.READ}/${notify_id}`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, '');
  }

  public httpReadAllNotify(): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_Routes.HEADER.READ_ALL}`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, {});
  }
}
